import Slider from 'react-slick';

import { SectionTitle } from '../../components';

import './reviews.scss';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ data }) => {
  return (
    <section className="reviews" id="reviews">
      <div className="container">
        <div className="reviews__inner">
          <SectionTitle title={data.title} subtitle={data.subtitle} />

          <Slider className="reviews__list" {...sliderSettings}>
            {data.reviews.map((el, index) => (
              <div key={index} className="review-card">
                <div className="stars">
                  <img src="./assets/stars.svg" alt={el.name} />
                </div>

                <div className="name">{el.name}</div>

                <div className="city">{el.city}</div>

                <div className="text">{el.text}</div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
