import './hero.scss';

const Hero = ({ data, setModal }) => (
  <section className="hero">
    <div className="container">
      <div className="hero__inner">
        <div className="hero__content">
          <h1>{data.title}</h1>

          <p>{data.subtitle}</p>

          <div dangerouslySetInnerHTML={{ __html: data.description }} />

          <button className="yellow-btn" onClick={() => setModal(true)}>
            {data.button}
          </button>

          <div className="mobile-image" />
        </div>

        <div className="hero__image" />
      </div>
    </div>
  </section>
);

export default Hero;
