import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

import './header.scss';

const Header = ({ logo, phone, setModal }) => {
  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <div className="page-header__inner">
          <NavLink to="/" className="logo">
            <img src={logo} alt="We Fix Academy" />
          </NavLink>

          <nav className="navigation">
            <ul>
              <li>
                <Link activeClass="active" to="course" spy={true} smooth={true} offset={-200} duration={500}>
                  Course
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="reviews" spy={true} smooth={true} offset={-200} duration={500}>
                  Testimonials
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="about-us" spy={true} smooth={true} offset={-200} duration={500}>
                  About
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="footer" spy={true} smooth={true} offset={-100} duration={500}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          <nav className="buttons">
            <a
              href="https://wefix-appliance.smartexpert.io/login"
              target="_blank"
              rel="noReferrer"
              className="login-btn"
            >
              Log In
            </a>
            <button className="yellow-btn" onClick={() => setModal(true)}>
              Enroll
            </button>
            <a href={`tel:${phone}`} className="phone">
              {phone}
            </a>

            <a
              href="https://wefix-appliance.smartexpert.io/login"
              target="_blank"
              rel="noReferrer"
              className="login-btn mobile"
            >
              Log In
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
