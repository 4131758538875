import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

import { Spinner } from './components';

import HomePage from './pages/HomePage';

export const ADMIN_URL = 'https://admin.wefix-appliance.co/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/academy?slug=landing-page`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Routes>
      <Route path="/" element={<HomePage data={data[0]?.acf} />} />
    </Routes>
  );
};

export default App;
