import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer } from '../';

import './layout.scss';

const Layout = ({ data, setModal, children }) => {
  const [isVisible, setVisibility] = useState(false);

  const handleScroll = () => {
    setVisibility(window.scrollY > 250);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <Header logo={data.logos.header} phone={data.phone} setModal={setModal} />
      <main>{children}</main>
      <Footer
        logo={data.logos.footer}
        phone={data.phone}
        email={data.email}
        address={data.address}
        hours={data.working_hours}
        social_networks={data.social_networks}
      />
      <ToastContainer />
      <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
        <a href={`tel:${data.phone}`}>{data.phone}</a>
        <button onClick={() => setModal(true)}>Enroll Now</button>
      </div>
    </div>
  );
};

export default Layout;
