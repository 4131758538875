import Slider from 'react-slick';

import { SectionTitle } from '../../components';

import './gallery.scss';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Gallery = ({ data }) => (
  <section className="gallery">
    <div className="container">
      <SectionTitle title="Our Team" subtitle="Gallery" />

      <div className="gallery__inner">
        {data.map((el, index) => (
          <div key={index}>
            <img src={el} alt={`gallery item ${index}`} />
          </div>
        ))}
      </div>

      <Slider className="gallery__mobile" {...sliderSettings}>
        {data.map((el, index) => (
          <div key={index}>
            <img src={el} alt={`gallery item ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  </section>
);

export default Gallery;
