import { SectionTitle } from '../../components';

import './course.scss';

const Course = ({ data, setModal }) => {
  return (
    <section className="course" id="course">
      <div className="container">
        <SectionTitle title={data.title} subtitle={data.subtitle} />

        <div className="course__timeline">
          {data?.timeline.map((el, index) => (
            <div className="course__timeline--item" key={index}>
              <div className="icon">
                <img src={el.icon} alt={el.title} />
              </div>

              <div className="stage">Stage {index + 1}</div>

              <h4 className="title">{el.title}</h4>

              <p className="salary">{el.salary}</p>

              <p className="description">{el.description}</p>

              <button className="yellow-btn" onClick={() => setModal(true)}>
                Enroll Now
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Course;
