import './sectionTitle.scss';

const SectionTitle = ({ title, subtitle }) => (
  <div className="section-title">
    <p className="subtitle">{subtitle}</p>
    <img src="../assets/section-title.svg" alt={title} />
    <h2 className="title">{title}</h2>
  </div>
);

export default SectionTitle;
