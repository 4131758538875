import './cta.scss';

const CallToAction = ({ data, phone, setModal }) => (
  <section className="call-to-action">
    <div className="container">
      <div className="call-to-action__inner">
        <h3>{data.title}</h3>

        <p>{data.subtitle}</p>

        <div className="links">
          <button className="yellow-btn" onClick={() => setModal(true)}>
            {data.button}
          </button>

          <span>
            or call us <a href={`tel:${phone}`}>{phone}</a>
          </span>
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;
