import axios from 'axios';
import { toast } from 'react-toastify';

import './modal.scss';

const MAIL_URL = `https://admin.wefix-appliance.co/wp-json/contact-form-7/v1/contact-forms/588/feedback`;

const Modal = ({ setModal }) => {
  const submit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const response = await axios.post(MAIL_URL, data);

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
        setModal(false);
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-background" onClick={() => setModal(false)} />
      <div className="modal-inner">
        <div className="close-btn" onClick={() => setModal(false)} />
        <div className="title">Registration</div>
        <form onSubmit={submit}>
          <input type="text" placeholder="First Name" name="first_name" required />
          <input type="text" placeholder="Last Name" name="last_name" required />

          <select name="level" required>
            <option hidden>Level</option>
            <option value="Entry Level">Entry Level</option>
            <option value="Junior Level">Junior Level</option>
            <option value="Middle Level">Middle Level</option>
            <option value="Senior Level">Senior Level</option>
          </select>

          <input type="tel" placeholder="Phone Number" name="phone" required />
          <input type="email" placeholder="Email" name="email" required />

          <input type="submit" value="Enroll" className="yellow-btn" />
        </form>
      </div>
    </div>
  );
};

export default Modal;
