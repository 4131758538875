import { useState } from 'react';

import { Layout, Seo, Modal } from '../components';

import { Hero, Course, CallToAction, Reviews, AboutUs, Gallery, CallUs } from '../sections';

const HomePage = ({ data }) => {
  const [modal, setModal] = useState(false);

  return (
    <Layout data={data.contacts} setModal={setModal}>
      <Seo />
      <Hero data={data.hero} setModal={setModal} />
      <Course data={data.course} setModal={setModal} />
      <CallToAction data={data.call_to_action} phone={data.contacts.phone} setModal={setModal} />
      <Reviews data={data.reviews} />
      <AboutUs data={data.about_us} />
      <Gallery data={data.gallery} />
      <CallUs data={data.call_us} phone={data.contacts.phone} setModal={setModal} />

      {modal && <Modal setModal={setModal} />}
    </Layout>
  );
};

export default HomePage;
