import { SectionTitle } from '../../components';

import './about.scss';

const AboutUs = ({ data }) => (
  <section className="about-us" id="about-us">
    <div className="container">
      <SectionTitle title={data.title} subtitle={data.subtitle} />

      <div className="about-us__inner">
        <div className="content" dangerouslySetInnerHTML={{ __html: data.text }} />

        <div className="image" />
      </div>
    </div>
  </section>
);

export default AboutUs;
