import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

import './footer.scss';

const Footer = ({ logo, phone, email, address, hours, social_networks }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="page-footer" id="footer">
      <div className="container">
        <NavLink to="/" className="logo">
          <img src={logo} alt="We Fix Academy" />
        </NavLink>

        <div className="page-footer__inner">
          <ul>
            <li className="address">{address}</li>
            <li className="phone">{phone}</li>
            <li className="email">{email}</li>
          </ul>

          <nav>
            <ul>
              <li>
                <Link activeClass="active" to="course" spy={true} smooth={true} offset={-200} duration={500}>
                  Course
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="reviews" spy={true} smooth={true} offset={-200} duration={500}>
                  Testimonials
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="about-us" spy={true} smooth={true} offset={-200} duration={500}>
                  About
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="footer" spy={true} smooth={true} offset={-100} duration={500}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          <div>
            <div className="hours">
              <p>Hours of Operation</p>
              <span dangerouslySetInnerHTML={{ __html: hours }} />
            </div>

            <div className="social-networks">
              <span>Follow Us On</span>

              <ul>
                {social_networks.map((el, index) => (
                  <li key={index}>
                    <a
                      href={el.link}
                      target="_blank"
                      rel="noReferrer"
                      dangerouslySetInnerHTML={{ __html: el.icon.element }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="page-footer__underline">
          {/* <div className="legal-links">
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/terms-of-use">Terms Of Use</NavLink>
            <NavLink to="/accessibility-statement">Accessibility Statement</NavLink>
          </div> */}

          <p>Copyright © {currentYear} WeFix Appliance Repair. All rights reserved.</p>

          <a href="http://toporin.com/" target="_blank" rel="noReferrer">
            Designed by <strong>Toporin Studio®</strong> in California
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
